  import axios from "axios"
  const param=JSON.parse(localStorage.getItem("param"))
  const Gfunc=require('../Gfunc')


  export const axiosGet=async (page,service, size)=>{
          const response = await axios.get( param.urlService+service+'?do=get&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
          .catch(function(error){
            error["data"]=""
            return error
          })
          return response ;
  }
  export const axiosGet2=async (page,service,action, size)=>{
    const response = await axios.get( param.urlService+service+'?do='+action+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()})
    .catch(function(error){
      error["data"]=""
      return error
    })
    return response ;
}

  export function axiosEdit(service,row,edt,attt){
    const response= axios({
      method: 'POST',
      url:param.urlService+service+'?do=upd',
      headers: Gfunc.getHeader(),
      data:JSON.stringify({ id: row.DT_RowId,att: attt,val:edt})    
  }).catch(function(error){
    error["data"]=""
    return error
  })
      return response;
}
export const axiosEdit2=async(service,action,row,edt,attt,idd,type,supp,suppAtt)=>{
  var id=row.DT_RowId
  if(!row.DT_RowId)
  {id='c'+row[idd]
  edt=(type==='text')?edt:parseInt(edt)
  }
  var data={ id:id,att: attt,val:edt}
  if(supp&&suppAtt){
    data[suppAtt]=supp
  }
  const response= await axios({
    method: 'POST',
    url:param.urlService+service+'?do='+action,
    headers: Gfunc.getHeader(),
    data:JSON.stringify(data)    
  }).catch(function(error){
    error["data"]=""
    return error
  })
    return response;
}

  export function axiosSort(column,sortdirection,page,size,service,action){
            const response=axios.get( param.urlService+service+'?do='+action+'&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()});
            return response
  }
  export function axiosSort2(column,sortdirection,page,size,service,get){
    const response =  axios.get( param.urlService+service+'?do='+get+'&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()});
    return response
}
  export function axiosSort3(column,val,sortdirection,page,size,service,get){
    const response =  axios.get( param.urlService+service+'?do='+get+'&column='+column.sortField+'&direct='+sortdirection+'&page='+page+"&per_page="+size+"&usr="+val,{ headers: Gfunc.getHeader()});
    return response
}

  export function axiosSearch(service,recherche,page,size,action){
    const response =  axios.get( param.urlService+service+'?do='+action+'&search='+recherche+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()});
    return response
  }
  export function axiosSearch1(service,val,recherche,page,size,action){
    const response =  axios.get( param.urlService+service+'?do='+action+'&search='+recherche+'&page='+page+"&per_page="+size+"&usr="+val,{ headers: Gfunc.getHeader()});
    return response
  }

  export function axiosFilter(service,filter,page,size,action){
    const response =  axios.get( param.urlService+service+'?do='+action+'&filter='+filter+'&page='+page+"&per_page="+size,{ headers: Gfunc.getHeader()});
    return response
  }

  
  