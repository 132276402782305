import React,{useState} from 'react';
import {DialogContent,Typography,Paper,Dialog,IconButton,Button} from '@material-ui/core';
import Draggable from 'react-draggable';
import {paperPropsSmallF } from '../../../css/muiStyle';
import {Close} from '@material-ui/icons';
import { withStyles} from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import LogoBq from '../../../img/logos/bq-b.png'


const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle  className={classes.root} {...other}>
        <Typography >{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

//Add a new role
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mag_prm_logo(props) {
    const Gfunc=require('../../../Gfunc')
    const [open,setOpen]=useState(false)
    const [nom,setNom]=useState('')
    const service='_mag.php'
    

    const handleClickOpen = () => { 
      setOpen(true); setNom('');
    };

    const handleClose = () => { setOpen(false); };
    
    const UpdateImg=async(file)=>{
        var formData = new FormData();
        const idd=parseInt(props.row.DT_RowId.substr(1))
        formData.append("img", file);
        formData.append("id",idd);
        const response = await Gfunc.axiosPostActionLog(service ,'updimg',formData)
        if(response.data.err&&response.data.err!=='-1'){
          setOpen(false)
          props.handleAlert(true,"error",response.data.err)  
        }else{
          Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                document.getElementById('mag').src=props.link+props.logo+'?'+new Date().getTime();
                setOpen(false)
                props.handleAlert(true,"success"," L'image a été modifié avec succès")    
              }
           })
        }
      }
    
  return (
    <div>
       <img src={props.link+props.logo} height={70} alt='logo' id='mag' onClick={handleClickOpen} style={{cursor:'pointer'}}
            onError={(e) => {
              e.target.onerror = null
              e.target.src = LogoBq }}/>
      <Dialog 
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
          <DialogTitle id="simple-dialog-title" onClose={handleClose}><label id='ess'>Modifier Logo :</label></DialogTitle>
          <DialogContent>
            <div style={{gridGap:'5px',display:'flex',alignItems:'center'}}>
            <Button variant="contained" color='primary' component="label">
             Choisir un logo
             <input
                type="file" hidden accept='.jpg'
                //accept={'image/*'}
                onChange={e=>{UpdateImg(e.target.files[0],e.target.files[0].name);setNom(e.target.files[0].name)}}
              />
            </Button>
            {nom}
            </div>
         </DialogContent>
      </Dialog>
      </div>
  );
}
