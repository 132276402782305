import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Divider from '@mui/material/Divider';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles,paperProps } from '../../../css/muiStyle';
import { Autocomplete } from '@material-ui/lab';
import {AlertCompo} from '../../../js/components'
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import $ from 'jquery'

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mag_man_add(props) {
    const Gfunc=require('../../../Gfunc')
    const classes=useStyles();
    const [mtn,setMtn]=useState('');
    const [mag,setMag]=useState('');
    const [vit,setVit]=useState('');
    const [adr,setAdr]=useState('');
    const [tel,setTel]=useState('');
    const [ent1,setEnt1]=useState('');
    const [ent2,setEnt2]=useState('');
    const [note,setNote]=useState('');
    const [fb,setFb]=useState('');
    const [etq,setEtq]=useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(Gfunc.YearAfter(new Date()));
    const [selectTyp,setSelectTyp]=useState('');
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_mag.php"

    //maxlength phone number=10 
    $(document).ready(function(){
       $('#tel-edit').on('input', function(ev) {
       var $this = $(this);
       var value = $this.val();
       if (value && value.length >10) {
            $this.val(value.substr(0,10));
         }
        })
            });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
  const handleClickOpen = () => { 
      setMag('');setAdr('');setTel('');setSelectTyp('');setMtn('');setEnt2('');setVit('')
      setSelectedDate(new Date());setSelectedDate1(Gfunc.YearAfter(new Date()));
      setOpen(true); 
    };

  const handleClose = () => { setOpen(false); };

  const handleSubmit= async(e) =>{
        const type=props.typ[Gfunc.getIndex(props.typ,selectTyp,"name")].id
        const response=await axiosPostAction(service,"add",{mag:mag,vitrine:vit,adresse:adr,tel:tel,typId:type,
                    date1:Gfunc.formatDate(selectedDate),date2:Gfunc.formatDate(new Date(selectedDate1)),mtn:parseFloat(mtn),note:note,
                    etiquette:etq,entet_tiket_1:ent1,entet_tiket_2:ent2,facebook:fb})
        if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
        }else{
          axiosResponse(response,()=>{
            if(response.data.jData){
                props.handleAlert(true,'success',"Le magasin est ajouté avec succès")
                props.callback();
                setOpen(false);
            }
        })
      }
    }

  //test if phone respect fromat phone 
  const testPhone=(e)=>{
    const re = /^[0-9\b]+$/;
    if (e.target.value==='' || re.test(e.target.value)) {
      setTel(e.target.value)
    }
  }

  //handle date change 
  const handleDateChange = (date) => {
      setSelectedDate(date);
  }

  //handle date change 
  const handleDateChange1 = (date) => {
      setSelectedDate1(date);
  }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>Nouveau Magasin</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperProps}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouveau magasin </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
             <div id='mag-add1'>
              <TextField className='smallText' variant='outlined' size="small" label='Magasin'  value={mag} margin='dense'
                        onChange={e =>setMag(e.target.value)} fullWidth  required autoFocus />
              <TextField className='smallText' variant='outlined' size="small" label='Vitrine'  value={vit} margin='dense'
                        onChange={e =>setVit(e.target.value)} fullWidth  required  />
              <TextField className='smallText' variant='outlined' size="small" label='Adresse'  value={adr} margin='dense'
                        onChange={e =>setAdr(e.target.value)} fullWidth required />
              <TextField  type="tel" label='Tel' id="tel-edit" name="iTel" value={tel}  className='smallText' margin='dense' variant='outlined'
                          onChange={(e)=>{testPhone(e)}} required
                          inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"}}
                          /> 
             </div>
             <div id='mag-add'>
                  <Autocomplete
                      disableClearable
                      className='smallAuto' 
                      id="iTyp"
                      onChange={(e,newValue) =>{setSelectTyp(newValue);}} 
                      onFocus={props.handleOpenTypM}
                      value={selectTyp}
                      options={props.typ.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Type"
                          size="small" 
                          margin='dense' variant='outlined'
                          required/>
                                )} /> 
                 
                 <TextField label='Facebook' name="fb" value={fb}  className='smallText' margin='dense' variant='outlined'
                           onChange={(e)=>{setFb(e.target.value)}} required />  
              </div>
              <div id='mag-add-row1'>
                <TextField type='text'  label='Étiquette' id='Etq' name="Etq" value={etq}  className='smallText' margin='dense' variant='outlined'
                           onChange={(e)=>{setEtq(e.target.value)}} /> 
                <TextField label='Entête ticket 1' name="Ent1" value={ent1}  className='smallText' margin='dense' variant='outlined'
                           onChange={(e)=>{setEnt1(e.target.value)}}  /> 
                <TextField label='Entête ticket 2' name="Ent2" value={ent2}  className='smallText' margin='dense' variant='outlined'
                           onChange={(e)=>{setEnt2(e.target.value)}}  /> 
              </div>
              <Divider textAlign="center">Abonnement</Divider>
              <div id='mag-add-row'>
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      minDate={new Date()} 
                      invalidDateMessage="Le format de la date est incorrect"   
                      minDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                      maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                      value={selectedDate}
                      onChange={handleDateChange}
                      variant="inline" label="Date début" margin="dense" size="small" inputVariant="outlined" className='smallText'
                      format="dd/MM/yyyy" name="iDate" id="date-picker-inline"required/>
                </MuiPickersUtilsProvider> 
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        disableToolbar
                        minDate={new Date()} 
                        invalidDateMessage="Le format de la date est incorrect"   
                        minDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                        maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                        value={selectedDate1}
                        onChange={handleDateChange1}
                        variant="inline" label="Date fin" inputVariant="outlined" format="dd/MM/yyyy" name="iDate" className='smallText'
                        margin="dense" size="small" id="date-picker-inline" required/>
                </MuiPickersUtilsProvider> 
                <TextField className='smallText' variant='outlined' size="small" label='Montant'  value={mtn} margin='dense'
                        onChange={e =>setMtn(e.target.value)} fullWidth required />
              </div>
              <Divider/>
              <div id='note'>
                <TextField size="small" label='Note' className='smallNote' margin="dense" id="iNote" name="iNote"  value={note}
                           onChange={e =>setNote(e.target.value)} placeholder="Insérer une note" multiline variant="outlined" minRows={2}/> 
              </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
                <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
       </Dialog>
      </div>
  );
}
