import React from "react";



export default function Usr_ses(){
    return(
        <div className="content">
       
        </div>
    )
}