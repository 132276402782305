import React,{useState,useRef,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useStyles, paperPropsSmallF } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import $ from 'jquery'

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mag_man_info_usr(props) {
    const Gfunc=require('../../../Gfunc')
    const classes=useStyles();
    const [usrs, setUsrs] = useState([]);
    const [selectUsr, setSelectUsr] =useState('');
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_mag.php"

    $(document).ready(function(){
       $('#tel-edit').on('input', function(ev) {
       var $this = $(this);
       var value = $this.val();
       if (value && value.length >10) {
            $this.val(value.substr(0,10));
         }
        })
            });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
   const handleClickOpen = () => { 
      setOpen(true); 
    };
   const handleClose = () => { setOpen(false); };

    //fetch role
   const fetchUsr= useCallback(async() => {
      const response = await Gfunc.axiosPostAction(service,"getmunusers",{magId:parseInt(props.row.DT_RowId.substr(1))})
      if(response.data.err&&response.data.err!=="-1"){
         handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setUsrs(response.data.jData);   
      })
    },[Gfunc]);
    
     //fetch role handler
   const handleOpenUsr = useCallback(async () => {
     if(usrs.length===0) {fetchUsr()}
    },[usrs,fetchUsr])


  const handleSubmit= async(e) =>{
    const user=usrs[Gfunc.getIndex(usrs,selectUsr,"name")].id
    const response=await Gfunc.axiosPostAction('_usr.php','setumag',{idu:user,idr:props.det.id})
        if(response.data.err&&response.data.err!=="-1"){
             handleAlert(true,'error',response.data.err)
        }else
         Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
             handleAlert(true,'success',"L'utilisateur a été ajouté au magasin"+props.det.name+" avec succès")
             props.callback();
             setOpen(false)
            }
        })
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>Nouvel Utilisateur</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouvel utilisateur {(props.det.name)?'à '+props.det.name:''} </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
              <Autocomplete
                  disableClearable
                  className='smallAuto' 
                  id="iUser"
                  onChange={(e,newValue) =>{setSelectUsr(newValue);}} 
                  onFocus={handleOpenUsr}
                  value={selectUsr}
                  options={usrs.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextField {...params} label="Utilisateur" size="small" margin='dense' variant='outlined'/>
                                  )} /> 
            </DialogContent>
            <DialogActions>
              <Button variant="contained" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
              <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
