import React,{ useState, useEffect ,useCallback,useMemo} from "react";
import DataTable from "react-data-table-component";
import ContentEditable from 'react-contenteditable';
import TextField from '@material-ui/core/TextField';
import {Search,Person, Note, DateRange} from '@material-ui/icons';
import {CircularProgress,Tooltip,Box,TextareaAutosize,Paper} from '@material-ui/core';
import DraggableDialog from '../content/modals/mag_man_add'
import {AlertCompo,NotAllowedComponent,NoDataComponent,AutocompleteS} from "../../js/components"
import {customStyles}from '../../css/datatable-custom'
import { useStyles,textAreaEdit} from '../../css/muiStyle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import HistoDialog from "./modals/mag_man_info";
import { useContext } from 'react';
import ParamContext from '../../Contexte';
import '../../css/mag.css'


export default function Mag_man (props){
  const classes=useStyles()
  const param=useContext(ParamContext)
  const Gfunc=require('../../Gfunc')
  const librery=require('../../js/data_table_librery')
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [called, setCalled] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [search,setSearch]=useState('');  
  const [noData,setNoData]=useState(false);
  const [doSearch,setDoSearch]=useState(false);
  const [notAllowed,setNotAllowed]=useState(false);
  const [severity, setSeverity] =useState("info");
  const [open, setOpen] =useState(false);
  const [messageA, setMessageA] =useState('');
  const [errMessage,setErrMessage]=useState('');
  const [records,setRecords]=useState('');
  const [typ, setTyp] = useState([]);
  const [acc, setAcc] = useState([]);
  const [ph, setPh] = useState([]);
  const [at, setAt] = useState([]);
  const [selecto,setSelecto]=useState('')
  const [Item,setItem]=useState('')
  const [ItemN,setItemN]=useState('')
  const [note,setNote]=useState('');

  const service='_mag.php'

   //handle alerts properties
  const handleAlert=(open,severity,message)=>{
    setOpen(open)
    setSeverity(severity)
    setMessageA(message)
    setTimeout(function(){
      setOpen(false)
    }, 5000);
  }

  //get mag list
  const fetch= useCallback(async(page,servic = service, size = perPage) => {
    setCalled(true);
    setLoading(true);
    const response=await librery.axiosGet(page,servic,size+"&search="+search)
    if(response.data.err&&response.data.err!=="-1"){
        setErrMessage(response.data.err)
        setNotAllowed(true)
    }else{
    Gfunc.axiosResponse(response,async()=>{
      setRecords(response.data.recordsFiltered)
      if(response.data.data!==undefined){
        var inac=-1;var inat=-1;var inph=-1
        var accs=await fetchAccess()||[];
        var ats=await fetchAt()||[];
        var phs=await fetchPh()||[];
        response.data.data.map((el)=>{
         //if(accs.length!==0){
           inac= accs.findIndex((obj => obj.clientId===el.accountAcc))
         //}
         if(ats.length!==0){
          inat= ats.findIndex((obj => obj.clientId===el.accountAT))
         }
         if(phs.length!==0){
           inph= phs.findIndex((obj => obj.clientId===el.accountPh))
         }
         if(inac!==-1){
          el['acc']=accs[inac].client
         }else{el['acc']=''}
         if(inat!==-1){
          el['at']=ats[inat].client
         }else{el['at']=''}
         if(inph!==-1){
          el['ph']=phs[inph].client
         }else{el['ph']=''}
        })
        setData(response.data.data);
        setTotalRows(response.data.recordsFiltered);
        setLoading(false);
        setNoData(true)
        setDoSearch(true)
      }else{
        setDoSearch(false)
        setData([])
      }
    })
  }
  },[Gfunc,librery,perPage,search])

   //fetch acc
   const fetchAccess= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service,"getcliacc&mod=ALL")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data){
          setAcc(response.data.data);  
        }
      })
     return response.data.data; 
  },[Gfunc]);



//fetch airtime
const fetchAt= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service,"getcliat&mod=ALL")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data){
          setAt(response.data.data); 
        }
      })
      return response.data.data;
     },[Gfunc]);


//fetch airtime
const fetchPh= useCallback(async() => {
    const response = await Gfunc.axiosGetAction(service,"getcliph&mod=ALL")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.data){
          setPh(response.data.data); 
        }
      })
      return response.data.data;
},[Gfunc]);

  //execute each rendre
useEffect(() => {
    if(!called){fetch(1);}
}, [fetch,called]);

  //update field
  const handleOnEdit=useCallback(async(e,row,att)=>{
    const edt1=e.target.innerHTML
    const edt=Gfunc.DeleteSpace(edt1);
    if(att==='nom' ){
      if(row.user!==edt){
        const attrib=row.user;
        const response=await librery.axiosEdit(service,row,edt,'nom') 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{ row.user=edt ;e.target.innerHTML=edt})
        if(response.data.err) {e.target.innerHTML=attrib}
      }else{e.target.innerHTML=edt}
    }
    else
        if(row[att]!==edt){
          const attrib=row[att];
          const response=await librery.axiosEdit(service,row,edt,att) 
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
          }else
          Gfunc.axiosResponse(response,()=>{row[att]=edt;e.target.innerHTML=edt})
          if(response.data.err) {e.target.innerHTML=attrib}
        }else {e.target.innerHTML=edt}
  },[librery,Gfunc]);

  //fetch type
  const fetchTypM= useCallback(async() => {
      const response = await Gfunc.axiosGetAction(service,"getstmag")
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
            setTyp(response.data.jData);  
          }
      })
  },[Gfunc]);
  
  //handler type
  const handleOpenTypM = useCallback(async() => {
    if(typ.length===0) {fetchTypM()}
  },[typ,fetchTypM])

  const handleSelectE=useCallback((row,att)=>{
    setSelecto(row[att])
    setItem(row.DT_RowId)
    setItemN(att)
  },[])
  
  //handle update note
  const handleOnEditNote=useCallback(async(note,row,att,action)=>{
  const edt1=note
  var edt=Gfunc.DeleteSpace(edt1);
  if(row[att]!==edt){
    edt=edt.replaceAll("\n", "\r")
    const attrib=row[att];
    const response=await librery.axiosEdit2(service,action,row,edt,att) 
    if(response.data.err&&response.data.err!=='-1'){
      handleAlert(true,'error',response.data.err)
      setSelecto('');
    }else
    Gfunc.axiosResponse(response,()=>{row[att]=edt})
    if(response.data.err) {row[att]=attrib}
    }else {row[att]=edt}
    setNote('')
    setSelecto('')
  },[]);

  const columns =useMemo(
    () => [  
      {
        width:"20px !important",
        cell: (row) => {
           return (
             <HistoDialog row={row} handleAlert={handleAlert}/>
            );
          }
      },
      {
         name: "Magasin",
         selector:row=>row.mag,
         minWidth:"160px !important",
         sortField: "mag",
         sortable: true,
      },
      {
         name: "Vitrine",
         selector:row=>row.vitrine,
         minWidth:"160px !important",
         sortField: "mag",
         sortable: true,
         cell: (row) => {
          return (
            <div data-tooltip={(row.date!==undefined||row.dateUpd!==undefined)?((!row.dateUpd)?"Entré le: "+row.date +' par: ' +row.agent:"Édité le: "+row.dateUpd +' par: ' +row.agentUpd):''}
             className='tooltipU'>
                <ContentEditable
                    html={row.vitrine}
                    name="vitrine"
                    data-column="item"
                    className="content-editable"
                    disabled={false} 
                    onBlur={e => handleOnEdit(e,row,'vitrine')}
                />
            </div>
             );
           }
      },
      {
        name: "Type",
        selector:row=>row.type,
        width:"100px !important",
        sortField: "type",
        sortable: true,
        cell: (row) => { 
          return (
          <div >  
            <AutocompleteS
                service={service} action={'upd'} 
                defaultValue={row.type} row={row} list={typ} 
                id={'typeId'} name='type' attr='name' attri='id' onOpen={handleOpenTypM} handleAlert={handleAlert}
                className="content-editable"  
              />
          </div> 
          );
      }
      },
      {
        name: "Airtime",
        selector:row=>row.accountAT,
        minWidth:"100px !important",
        sortField: "accountAT",
        sortable: true,
        cell: (row) => { 
          return (
          <div>  
            <AutocompleteS
                service={service} action={'upd'} mag='mag-at'
                defaultValue={row.at} row={row} list={at} 
                id={'accountAT'} name='at' attr='client' attri='clientId' handleAlert={handleAlert}
                className="content-editable"  
              />
          </div> 
          );
      }
      },
      {
        name: "Accessoire",
        selector:row=>row.accountAcc,
        minWidth:"100px !important",
        sortField: "accountAcc",
        sortable: true,
        cell: (row) => { 
          return (
          <div >  
            <AutocompleteS
                service={service} action={'upd'} mag='mag-acc'
                defaultValue={row.acc} row={row} list={acc} 
                id={'accountAcc'} name='acc' attr='client' attri='clientId'  handleAlert={handleAlert}
                className="content-editable"  
              />
          </div> 
          );
      }
      },
      {
        name: "Phone",
        selector:row=>row.accountPh,
        minWidth:"100px !important",
        sortField: "accountPh",
        sortable: true,
        cell: (row) => { 
          return (
          <div >  
            <AutocompleteS
                service={service} action={'upd'} mag='mag-ph'
                defaultValue={row.ph} row={row} list={ph} 
                id={'accountPh'} name='ph' attr='client' attri='clientId' handleAlert={handleAlert}
                className="content-editable"  
              />
          </div> 
          );
      }
      },
      {
        name: "Note",
        selector:row=>row.note,
        minWidth:"180px !important",
        sortField: "note",
        sortable: true,
        cell: (row) => { 
          return (
            <div className="note-container">
             { 
              (row.note===selecto && ItemN==='note' && row.DT_RowId===Item)?
                <ClickAwayListener onClickAway={()=>{(note)?handleOnEditNote(note,row,"note",'upd'):setSelecto('')}}>
                <TextareaAutosize style={textAreaEdit} value={note}  onChange={(e)=>{setNote(e.target.value);}}
                /></ClickAwayListener>:
                ((row.note&&row.note.match(/\r/g) || []).length>=1)?
                <Tooltip title={row.note.replaceAll("\r", "/ ")}>
                    <div className="content-editable" onClick={()=>{handleSelectE(row,'note')}}>{row.note.substring(0, row.note.indexOf("\r"))+"..."}</div>
                </Tooltip>:
                  <Tooltip title={row.note}>
                <div className="content-editable" onClick={()=>{handleSelectE(row,'note')}} >{row.note}</div></Tooltip>
             }
            </div> 
          );
      }
      },
      
    ],[typ,note,selecto,Item,ItemN,ph,acc,,at,handleOnEditNote,handleSelectE,
       handleOnEdit,handleOpenTypM]);
  
  //sort table
  const handleOnSort=async(column, sortdirection,page=currentPage, size = perPage+"&search="+search)=>{
      setLoading(true);
      const response=await librery.axiosSort(column,sortdirection,page=currentPage,size,service,'get')
      setLoading(false)
      if(response.data.err&&response.data.err!=="-1"){
        handleAlert(true,'error',response.data.err)
      }else{
          Gfunc.axiosResponse(response,()=>{
            if(response.data.data){
              setData(response.data.data);
              setTotalRows(response.data.recordsFiltered);
              setLoading(false);
            }
          }) 
      }    
  }

    //recherche serverside
  const handleSearch=async(size = perPage)=>{
    setLoading(true);
    const recherche=search;
    const response=await librery.axiosSearch(service,recherche,1,size,"get")
    setLoading(false);
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else{
    Gfunc.axiosResponse(response,()=>{
      if(response.data.data!==undefined){
      setData(response.data.data);
      setTotalRows(response.data.recordsFiltered);
      }else setData([])
    })  
    }
    setLoading(false); 
    return recherche
  }
  
  //change page
  function handlePageChange (page) {
    fetch(page);
    setCurrentPage(page);
  };

  //set perPage value
  const handlePerRowsChange = async (newPerPage, page) => {
      fetch(1,service, newPerPage);
      setPerPage(newPerPage);
    };

  function liveSearch(e,search){
    if(param.liveSearch&&search.length>3){
      e.preventDefault();
      handleSearch();
    }else{ if (e.keyCode === 13) {
              e.preventDefault();
              handleSearch();
            }
    }
  }

  function liveSearch2() {
    handleSearch();
  }

  const ExpanableComponent =({data}) => {
      const [dig, setDig] = useState([]);
      const [airDet, setAirDet] = useState([]);
      const [airG, setAirG] = useState([]);
      const [acc, setAcc] = useState([]);
      const [phone, setphone] = useState([]);
      
      //fetch airtime det
      const fetchAirDet= useCallback(async() => {
        var dat=[]
        const response = await Gfunc.axiosGetAction(service,"getatdtyp")
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          Object.keys(response.data.jData).map((key, index)=> {
              if(key===''){
                dat.push({'id':'empty','name':response.data.jData[key]})
              }else{
                dat.push({'id':parseInt(key),'name':response.data.jData[key]})
              }
            return true
          }) 
            setAirDet(dat);   
        })
      },[Gfunc]);

      //handler airtime
      const handleOpenAirDet = useCallback(async() => {
      if(airDet.length===0) {fetchAirDet()}
      },[airDet,fetchAirDet])

        //fetch accessoires
      const fetchAirG= useCallback(async() => {
        var dat=[]
        const response = await Gfunc.axiosGetAction(service,"getatgtyp")
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else
          Gfunc.axiosResponse(response,()=>{
            Object.keys(response.data.jData).map((key, index)=> {
              if(key===''){
                dat.push({'id':'empty','name':response.data.jData[key]})
              }else{
                dat.push({'id':parseInt(key),'name':response.data.jData[key]})
              }
              return true
            }) 
              setAirG(dat);   
          })
      },[Gfunc]);

      //handler airtime gros
      const handleOpenAirG = useCallback(async() => {
      if(airG.length===0) {fetchAirG()}
      },[airG,fetchAirG])
 
       //fetch digit
  const fetchDig= useCallback(async() => {
    var dat=[]
    const response = await Gfunc.axiosGetAction(service,"getdigtyp")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
      Gfunc.axiosResponse(response,()=>{
        if(response.data.jData){
          Object.keys(response.data.jData).map((key, index)=> {
            if(key===''){
              dat.push({'id':'empty','name':response.data.jData[key]})
            }else{
              dat.push({'id':parseInt(key),'name':response.data.jData[key]})
            }
          return true
        }) 
          setDig(dat);
        }
      })
  },[Gfunc]);

  //handler type
  const handleOpenDig = useCallback(async() => {
  if(dig.length===0) {fetchDig()}
  },[dig,fetchDig])

  //fetch phone list
  const fetchPhone= useCallback(async() => {
    var dat=[]
    const response = await Gfunc.axiosGetAction(service,"getphonetyp")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      Object.keys(response.data.jData).map((key, index)=> {
        if(key===''){
          dat.push({'id':'empty','name':response.data.jData[key]})
        }else{
          dat.push({'id':parseInt(key),'name':response.data.jData[key]})
        }
        return true
      }) 
        setphone(dat);   
    })
  },[Gfunc]);

  //handler phone
  const handleOpenPhone = useCallback(async() => {
  if(phone.length===0) {fetchPhone()}
  },[phone,fetchPhone])

  //fetch accessoires
  const fetchAcc= useCallback(async() => {
    var dat=[]
    const response = await Gfunc.axiosGetAction(service,"getacctyp")
    if(response.data.err&&response.data.err!=="-1"){
      handleAlert(true,'error',response.data.err)
    }else
    Gfunc.axiosResponse(response,()=>{
      Object.keys(response.data.jData).map((key, index)=> {
        if(key===''){
          dat.push({'id':'empty','name':response.data.jData[key]})
        }else{
          dat.push({'id':parseInt(key),'name':response.data.jData[key]})
        }
        return true
      }) 
        setAcc(dat);   
    })
  },[Gfunc]);

  //handler acc
  const handleOpenAcc = useCallback(async() => {
  if(acc.length===0) {fetchAcc()}
  },[acc,fetchAcc])

   return (
      <div className="row-expenderr">
        <Paper id='mag-expended'>
            <div id="mag-expended-info">
              <Person className={classes.blackIconn}/>
              <span className="desc">Agent:</span>{data.agent}
            </div>
            <div id="mag-expended-info">
              <DateRange className={classes.blackIconn}/>
              <span className="desc">Date:</span>{data.date}
            </div>
        </Paper>
        <fieldset className="fieldsetTop" id="ctrlA">
          <legend>Contrôle d'accès</legend>
          <div id="row-1">
              <fieldset className="fieldInf" >
                  <legend> Airtime Detail:</legend>
                  <AutocompleteS
                      service={service} action={'upd'} 
                      defaultValue={data.airtimeRetail} row={data} list={airDet} 
                      id={'airtimeRetail'} name='airtimeRetailId' attr='name' attri='id' onOpen={handleOpenAirDet} handleAlert={handleAlert} 
                      smen={'art'+data.DT_RowId} mag='mag' className="content-editable-start"
                  />
              </fieldset>
              <fieldset className="fieldInf" >
                  <legend> Airtime Gros:</legend>
                  <AutocompleteS
                    service={service} action={'upd'} 
                    defaultValue={data.airtimeWhole} row={data} list={airG} 
                    id={'airtimeWhole'} name='airtimeWholeId' attr='name' attri='id' onOpen={handleOpenAirG} handleAlert={handleAlert}
                    handleResponsee={(edt)=>{ setSelecto('') }} mag='mag'  className="content-editable-start"
                  />
              </fieldset> 
          </div> 
          <div id="row-2">
              <fieldset className="fieldInf" >
                  <legend> Digital:</legend>
                  <AutocompleteS
                      service={service} action={'upd'} 
                      defaultValue={data.digital} row={data} list={dig} 
                      id={'digital'} name='digitalId' attr='name' attri='id' onOpen={handleOpenDig}  handleAlert={handleAlert} mag='mag'
                      className="content-editable-start"
                  />
              </fieldset>
              <fieldset className="fieldInf" >
                  <legend> Phone:</legend>
                  <AutocompleteS
                    service={service} action={'upd'} 
                    defaultValue={data.phone} row={data} list={phone} 
                    id={'phone'} name='phoneId' attr='name' attri='id' onOpen={handleOpenPhone} handleAlert={handleAlert} mag='mag'
                    className="content-editable-start"
                  />
              </fieldset>
              <fieldset className="fieldInf" >
                  <legend> Accessoire:</legend>
                  <AutocompleteS
                    service={service} action={'upd'} 
                    defaultValue={data.accessoire} row={data} list={acc} mag='mag'  className="content-editable-start"
                    id={'accessoire'} name='accessoireId' attr='name' attri='id' onOpen={handleOpenAcc} smen={'acc'+data.DT_RowId} handleAlert={handleAlert} 
                  />
              </fieldset>
          </div> 
          <br/>
        </fieldset>
      </div>
          )
   }

  return (
    <div className="content">
        <AlertCompo severity={severity} open={open} setOpen={setOpen} message={messageA} />
            <div className='table-header'>
                <div className='button-flex-wrapper'><DraggableDialog callback={()=>{ fetch(1)}} handleAlert={handleAlert}
                     handleOpenTypM={handleOpenTypM} typ={typ} />
                </div>
                <div className='search'>
                  {(doSearch)?
                  <div className='search' >
                      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                            <Search className={classes.blackIcon} sx={{ color: 'action.active', mr: 1, my: 0.5 }} onClick={(e)=>liveSearch2(e)}/>
                            <TextField className={classes.search} id="input-with-sx" label="Recherche" value={search} size="small" margin='none' onKeyUp={e => liveSearch(e)} onChange={e => setSearch(e.target.value)} />
                      </Box>
                  </div>:null}
                </div>
            </div>
           {
                ((data.length!==0 ||doSearch))?
                <div>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader={true}
                  noDataComponent={param.ErreurData}
                  paginationRowsPerPageOptions={[30,50,100]}
                  perPage={perPage}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                 // paginationResetDefaultPage={resetDP}
                  paginationDefaultPage={1}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  sortServer={true}
                  onSort={handleOnSort}
                  progressComponent={<CircularProgress className={classes.circularProgress} />}
                  highlightOnHover
                  customStyles={customStyles}
                  expandOnRowClicked
                  expandableRows 
                  expandableRowsComponent={ExpanableComponent}
                  paginationComponentOptions={{ rowsPerPageText: 'Élément par page:', rangeSeparatorText: 'sur', selectAllRowsItem: true,selectAllRowsItemText: 'Tous'}}
                />
            </div>:(notAllowed)?<NotAllowedComponent message={errMessage}/>:(noData || records===0)?<NoDataComponent message="Aucun résultat trouvé"/>:null}
    </div>    
  );
}



