
import {theme} from "./theme.js"

export const customStyles = {
  header: {
    style: {
      border: "solid 1px #e0e0e0",
      fontSize: '18px',
      textAlign:"center",
      color: "white",
      backgroundColor: '#9e9e9e',
      minHeight: '20px',
      height:"25px",
      width:"98%",
      maxWidth:"100%",
      margin:"5px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },  
  title: {
    style: {
      fontColor: 'red',
      fontWeight: '900',
    }
  },
  table: {
    style: {
      width: '98%',
      border: "solid 1px"+theme.palette.secondary.main,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop:'0px'
    }
  },
  rows: {
    style: {
      minHeight: '20px',
      maxHeight: '36px',
      height:'23px',
      color:"black",
    },
    highlightOnHoverStyle: {
      color: theme.palette.primary.light,
      backgroundColor: theme.palette.secondary.light,
      transitionDuration: '0.15s',
      transitionProperty: 'background-color',
      borderBottomColor: "black",
      outlineStyle: 'solid',
      outlineWidth: '1px',
      outlineColor: 'black',
    }
  },
  headCells: {
    style: {
      alignItems:'center',
      textAlign:"center",
      fontSize: '13px',
      justifyContent: 'center',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      color: "white",
      minWidth:"0px !important",
      padding:"0px 5px"
    },
    activeSortStyle: {
      color: "white",
      '&:hover:not(:focus)': {
        color: "white",
      },
    },
    inactiveSortStyle: {
      '&:focus': {
        color: "white",
      },
      '&:hover': {
        color: "white",
      },
    }
  },
  headRow: {
    style: {
      textAlign:"center",
      fontSize: '15px',
      justifyContent: 'center',
      alignItems:'center',
      height: "24px",
      backgroundColor:'#4a4848',
      width:"100% !important",
      minHeight:"24px !important",
      '&:hover': {
        color: "white !important",
     },
    }
  },
  cells: {
    style: {
      fontSize: '12px',
      fontWeight:'500 !important',
      textAlign: "center",
      justifyContent: 'center',
      minWidth:"0px !important",
      padding:"0px 5px"
    },
  },
   //expander
   expanderRow: {
    style: {
      fontSize: '13px',
      //minHeight: '20px',
      maxHeight: '500px',
      //height:"fit-content !important",
      //color: theme.text.primary,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  expanderCell: {
    style: {
      minHeight: '20px',
      maxHeight: '36px',
      height:'100%',
      flex: '0 0 30px',
    },
  },
  pagination: {
    style: {
      color: "black",
      fontSize: '13px',
      minHeight: '20px',
      minWidth: '20px',
      height:"40px",
      width:"98%",
      marginLeft: "auto",
      marginRight: "auto",
      backgroundColor: "white",
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: 'px',
      cursor: 'pointer',
      transition: '0.4s',
      color: "blue",
      //fill: theme.palette.secondary.dark,
      backgroundColor: 'transparent',
      '&:disabled': {
        cursor: 'unset',
        color: "red",
      fill: theme.palette.secondary.main,
      },
      '&:hover:not(:disabled)': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:focus': {
        outline: 'none',
        backgroundColor: "gray",
      },
    },
  },
};

  export const customStyles1 = {
    header: {
      style: {
        border: "solid 1px #e0e0e0",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: '#9e9e9e',
        minHeight: '20px',
        height:"25px",
        width:"90%",
        maxWidth:"100%",
        margin:"5px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
        marginRight: "15px",
      }
    },
    table: {
      style: {
        width: '90%',
        border: "solid 1px",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    rows: {
      style: {
        minHeight: '23px',
        height:'23px'
      },
      highlightOnHoverStyle: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary.light,
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        fontSize: '14px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "white",
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        height: "24px",
        backgroundColor:'black',
        minHeight:"24px !important",
        '&:hover': {
          color: "white !important",
       },
      }
    },
    cells: {
      style: {
        fontSize: '13px',
        padding: '1px 3px',
        textAlign: "center",
        justifyContent: 'center',
        width:'inherit'
      },
    },  
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"90%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px', 
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: theme.palette.secondary.dark,
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: "red",
        fill: theme.palette.secondary.main,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:focus': {
          outline: 'none',
          //backgroundColor: "green",
        },
      },
    },
  };
  export const customStylesBigHeight = {
    header: {
      style: {
        border: "solid 1px #e0e0e0",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor: '#9e9e9e',
        minHeight: '20px',
        height:"25px",
        width:"90%",
        maxWidth:"100%",
        margin:"5px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
        marginRight: "15px",
      }
    },
    table: {
      style: {
        width: '98%',
        border: "solid 1px",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    rows: {
      style: {
        minHeight: '23px',
        height:'30px'
      },
      highlightOnHoverStyle: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary.light,
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        fontSize: '14px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "white",
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        height: "24px",
        backgroundColor:'black',
        minHeight:"24px !important",
        '&:hover': {
          color: "white !important",
       },
      }
    },
    cells: {
      style: {
        fontSize: '13px',
        padding: '1px 3px',
        textAlign: "center",
        justifyContent: 'center',
        width:'inherit'
      },
    },  
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"90%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px', 
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: theme.palette.secondary.dark,
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: "red",
        fill: theme.palette.secondary.main,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:focus': {
          outline: 'none',
          //backgroundColor: "green",
        },
      },
    },
  };
  export const customStyles2 = {
    header: {
      style: {
        border: "solid 1px #e0e0e0",
        fontSize: '18px',
        textAlign:"center",
        color: "white",
        backgroundColor:theme.palette.secondary.main ,
        minHeight: '20px',
        height:"25px",
        width:"90%",
        maxWidth:"100%",
        margin:"5px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },  
    title: {
      style: {
        fontColor: 'red',
        fontWeight: '900',
        marginRight: "15px",
      }
    },
    table: {
      style: {
        width: '90%',
        border: "solid 1px",
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    rows: {
      style: {
        minHeight: '23px',
        height:'32px',
        maxHeight:'38px'
      },
      highlightOnHoverStyle: {
        color: theme.palette.primary.light,
        backgroundColor: theme.palette.secondary.light,
        transitionDuration: '0.15s',
        transitionProperty: 'background-color',
        borderBottomColor: "black",
        outlineStyle: 'solid',
        outlineWidth: '1px',
        outlineColor: 'black',
      }
    },
    headCells: {
      style: {
        fontSize: '14px',
        justifyContent: 'center',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: "white",
      },
      activeSortStyle: {
        color: "white",
        '&:hover:not(:focus)': {
          color: "white",
        },
      },
      inactiveSortStyle: {
        '&:focus': {
          color: "white",
        },
        '&:hover': {
          color: "white",
        },
      }
    },
    headRow: {
      style: {
        justifyContent: 'center',
        height: "24px",
        backgroundColor:theme.palette.secondary.main,
        minHeight:"24px !important",
        '&:hover': {
          color: "white !important",
       },
      }
    },
    cells: {
      style: {
        fontSize: '11px',
        padding: '1px 3px',
        justifyContent: 'center',
        width:'inherit'
      },
    },  
    pagination: {
      style: {
        color: "black",
        fontSize: '13px',
        minHeight: '20px',
        minWidth: '20px',
        height:"45px",
        width:"90%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
        borderTopStyle: 'solid',
        borderTopWidth: '1px', 
      },
      pageButtonsStyle: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        padding: '8px',
        margin: 'px',
        cursor: 'pointer',
        transition: '0.4s',
        color: "blue",
        fill: theme.palette.secondary.dark,
        backgroundColor: 'transparent',
        '&:disabled': {
          cursor: 'unset',
          color: "red",
        fill: theme.palette.secondary.main,
        },
        '&:hover:not(:disabled)': {
          backgroundColor: theme.palette.secondary.main,
        },
        '&:focus': {
          outline: 'none',
          //backgroundColor: "green",
        },
      },
    },
  };
  //a conditional style used in data table to apply a style in certain condition in rows only
  export const  conditionalRowStyles  =  [ 
    {
      when: row => row.isbanned===true,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
    {
      when: row => row.activ===0 ||row.activ===false,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
    {
      when: row => row.closed===1,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        }
    },
    {
      when: row => row.destroyed===1,
        style: {
              backgroundColor: "lightgray",
              color:"gray"
        },
    },
    {
      when: row => row.datusd!=null,
        style: {
              backgroundColor: theme.palette.bleu.light,
              textColor:"white"
        },
    },
    {
      when: row => row.alert===true,
        style: {
              color:"red"
        },
    },
    {
      when: row => row.toggleSelected,
      style: {
        backgroundColor: theme.palette.bleu.light,
        userSelect: "none",
        "&:hover":{
            backgroundColor: theme.palette.bleu.light,
        }
      }
    }
  ]; 

  //a conditional cell style used in data table to apply a style in certain condition in cell only
  export const mtnControl=[
    {
      when: row => row.mtn>=0,
        style: {
          color:"green",
          justifyContent:"end",
          paddingRight:'20px'
        }
    }, 
    {
      when: row => row.mtn<0,
        style: {
          color:"red",
          justifyContent:"end",
          paddingRight:'20px'
        }
    }, 
  ]
  export const mtnControlFourni=[
    {
      when: row => row.mtn>=0&&row.type!=='achat',
        style: {
          color:"green",
          justifyContent:"end",
          paddingRight:'20px'
        }
    }, 
    {
      when: row => row.mtn<0&&row.type!=='achat',
        style: {
          color:"red",
          justifyContent:"end",
          paddingRight:'20px'
        }
    }, 
  ]

   //a conditional cell style used in data table to apply a style in certain condition in cell only
   export const iconCell=
    {
          margin:"5px",
          width:"22px !important",
          maxWidth:" 22px !important",
          minWidth:" 22px !important",
    }
   //a conditional cell style used in data table to apply a style in certain condition in cell only
   export const iconsCell=
    {   
          width:"45px !important",
          maxWidth:" 22px !important",
          minWidth:" 22px !important",
    }
   export const numberColumn={
      justifyContent:"end !important",
      paddingRight:'20px !important'
    }
   export const numberColumnTicket={
      justifyContent:"end !important",
    }

    export const customStylesBon = {
      header: {
        style: {
          border: "solid 2px black",
          fontSize: '18px',
          textAlign:"center",
          color: "black",
          backgroundColor: theme.palette.secondary.main,
          minHeight: '20px',
          height:"25px",
          width:"95%",
          maxWidth:"100%",
          margin:"0px",
          paddingLeft:"0%",
          marginLeft: "auto",
          marginRight: "auto",
        },
      },  
      title: {
        style: {
          fontColor: 'red',
          fontWeight: '900',
        }
      },
      table: {
        style: {
          width: '95%',
          border: "solid 1px"+theme.palette.secondary.main,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop:'10px'
        }
      },
      rows: {
        style: {
          minHeight: '22px',
          maxHeight: '32px',
          height:"26px",
        },
        highlightOnHoverStyle: {
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.secondary.light,
          transitionDuration: '0.15s',
          transitionProperty: 'background-color',
          borderBottomColor: "black",
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: 'black',
        }
      },
      headCells: {
        style: {
          fontSize: '15px',
          justifyContent: 'center',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: "black",
          background:theme.palette.secondary.main,
        },
        activeSortStyle: {
          color: "white",
          '&:hover:not(:focus)': {
            color: "white",
          },
        },
        inactiveSortStyle: {
          '&:focus': {
            color: "white",
          },
          '&:hover': {
            color: "white",
          },
        }
      },
      headRow: {
        style: {
          fontSize: '15px',
          justifyContent: 'center',
          height: "30px",
          backgroundColor:'black',
          minHeight:"30px !important",
          '&:hover': {
            color: "white !important",
         },
        }
      },
      cells: {
        style: {
          fontSize: '13px',
          padding: '1px 3px',
          textAlign: "center",
          justifyContent: 'center',
        },
      },
       
    };


    export const customStylesTicket = {
      table: {
        style: {
          width: 'auto',
          border: "solid 1px black",
          borderRadius:"0 !important",
          marginLeft: "1px",
          marginRight: "1px",
          marginTop:'0px'
        }
      },
      rows: {
        style: {
          minHeight: '20px',
          maxHeight: '40px',
          color:"black",
          paddingBottom:"2px",
          paddingTop:"2px",
        },
        highlightOnHoverStyle: {
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.secondary.light,
          transitionDuration: '0.15s',
          transitionProperty: 'background-color',
          borderBottomColor: "black",
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: 'black',
        }
      },
      headCells: {
        style: {
          alignItems:'center',
          textAlign:"center",
          fontSize: '12px',
          justifyContent: 'center',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: "white",
          minWidth:"0px !important",
          padding:"0px 1px"
        },
        activeSortStyle: {
          color: "white",
          '&:hover:not(:focus)': {
            color: "white",
          },
        },
        inactiveSortStyle: {
          '&:focus': {
            color: "white",
          },
          '&:hover': {
            color: "white",
          },
        }
      },
      headRow: {
        style: {
          textAlign:"center",
          fontSize: '12px',
          justifyContent: 'center',
          alignItems:'center',
          height: "24px",
          backgroundColor:'black',
          width:"100% !important",
          minHeight:"24px !important",
          color:"black !important",
          '&:hover': {
            color: "black !important",
         },
        }
      },
      cells: {
        style: {
          fontSize: '11px',
          fontWeight:'600 !important',
          textAlign: "center",
          justifyContent: 'center',
          minWidth:"0px !important",
          padding:"0px 2px !important",
          whiteSpace:"pre-line !important",
          /*letterSpacing:"-1px"*/
        },
      },
    };


    export const customStylesTicket2 = {
      table: {
        style: {
          width: '98%',
          border: "solid 1px"+theme.palette.secondary.main,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop:'0px'
        }
      },
      rows: {
        style: {
          minHeight: '20px',
          maxHeight: '40px',
          color:"black"
        },
        highlightOnHoverStyle: {
          color: theme.palette.primary.light,
          backgroundColor: theme.palette.secondary.light,
          transitionDuration: '0.15s',
          transitionProperty: 'background-color',
          borderBottomColor: "black",
          outlineStyle: 'solid',
          outlineWidth: '1px',
          outlineColor: 'black',
        }
      },
      headCells: {
        style: {
          alignItems:'center',
          textAlign:"center",
          fontSize: '12px',
          justifyContent: 'center',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          color: "white",
          minWidth:"0px !important",
          padding:"0px 1px"
        },
        activeSortStyle: {
          color: "white",
          '&:hover:not(:focus)': {
            color: "white",
          },
        },
        inactiveSortStyle: {
          '&:focus': {
            color: "white",
          },
          '&:hover': {
            color: "white",
          },
        }
      },
      headRow: {
        style: {
          textAlign:"center",
          fontSize: '12px',
          justifyContent: 'center',
          alignItems:'center',
          height: "24px",
          backgroundColor:'black',
          width:"100% !important",
          minHeight:"24px !important",
          '&:hover': {
            color: "white !important",
         },
        }
      },
      cells: {
        style: {
          fontSize: '9px',
          fontWeight:'600 !important',
          textAlign: "center",
          justifyContent: 'center',
          minWidth:"0px !important",
          padding:"0px 2px !important",
          whiteSpace:"pre-line !important",
        },
      },
    };
  
      export const customStylesSmall = {
        header: {
          style: {
            border: "solid 1px #e0e0e0",
            fontSize: '18px',
            textAlign:"center",
            color: "white",
            backgroundColor: '#9e9e9e',
            minHeight: '20px',
            maxHeight: '20px',
            height:"23px",
            width:"98%",
            maxWidth:"100%",
            margin:"5px",
            marginLeft: "auto",
            marginRight: "auto",
          },
        },  
        title: {
          style: {
            fontColor: 'red',
            fontWeight: '900',
          }
        },
        table: {
          style: {
            width: '98%',
            border: "solid 1px"+theme.palette.secondary.main,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop:'0px'
          }
        },
        rows: {
          style: {
            minHeight: '15px',
            maxHeight: '15px',
            height:'15px'
          },
          highlightOnHoverStyle: {
            color: theme.palette.primary.light,
            backgroundColor: theme.palette.secondary.light,
            transitionDuration: '0.15s',
            transitionProperty: 'background-color',
            borderBottomColor: "black",
            outlineStyle: 'solid',
            outlineWidth: '1px',
            outlineColor: 'black',
          }
        },
        headCells: {
          style: {
            alignItems:'center',
            textAlign:"center",
            fontSize: ".7em",
            justifyContent: 'center',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            color: "white",
            minWidth:"0px !important",
            padding:"0px 10px"
          },
          activeSortStyle: {
            color: "white",
            '&:hover:not(:focus)': {
              color: "white",
            },
          },
          inactiveSortStyle: {
            '&:focus': {
              color: "white",
            },
            '&:hover': {
              color: "white",
            },
          }
        },
        headRow: {
          style: {
            textAlign:"center",
            fontSize: '15px',
            justifyContent: 'center',
            alignItems:'center',
            height: "22px",
            backgroundColor:'black',
            width:"100% !important",
            minHeight:"22px !important",
            '&:hover': {
              color: "white !important",
           },
          }
        },
        cells: {
          style: {
            fontSize: ".9em",
            fontWeight:'bold !important',
            textAlign: "center",
            justifyContent: 'center',
            minWidth:"0px !important",
            padding:"0px 10px"
          },
        },
         //expander
         expanderRow: {
          style: {
            fontSize: '13px',
            minHeight: '20px',
            maxHeight: '500px',
            //height:"22px",
            //color: theme.text.primary,
            backgroundColor: theme.palette.secondary.main,
          },
        },
        expanderCell: {
          style: {
            minHeight: '20px',
            maxHeight: '30px',
            height:"20px",
            flex: '0 0 30px',
          },
        },
        pagination: {
          style: {
            color: "black",
            fontSize: '13px',
            minHeight: '20px',
            minWidth: '20px',
            height:"40px",
            width:"98%",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "white",
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
            
          },
          pageButtonsStyle: {
            borderRadius: '50%',
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: 'px',
            cursor: 'pointer',
            transition: '0.4s',
            color: "blue",
            //fill: theme.palette.secondary.dark,
            backgroundColor: 'transparent',
            '&:disabled': {
              cursor: 'unset',
              color: "red",
            fill: theme.palette.secondary.main,
            },
            '&:hover:not(:disabled)': {
              backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
              outline: 'none',
              backgroundColor: "gray",
            },
          },
        },
      };