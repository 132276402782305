import React,{useState,useCallback,useMemo} from 'react';
import {DialogContent,Paper,Dialog,Typography,IconButton,Tab,Box,CircularProgress,FormControlLabel,Checkbox,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import {Info,Close,IndeterminateCheckBox} from '@material-ui/icons';
import ContentEditable from 'react-contenteditable';
import { useStyles, paperPropsPram} from '../../../css/muiStyle';
import {customStyles} from '../../../css/datatable-custom'
import {AlertCompo} from '../../../js/components'
import {TabContext,TabList,TabPanel} from '@material-ui/lab';
import $ from 'jquery'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { withStyles} from '@material-ui/core/styles';
import DataTable from "react-data-table-component";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/locale";
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import DialogLogo from './mag_prm_logo'
import DialogUsr from './mag_man_info_usr'
import DialogAb from './mag_man_info_abo'

    const styles = (theme) => ({
      root: {
        margin: 0,
        padding: theme.spacing(2),
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    });
    const DialogTitle = withStyles(styles)((props) => {
      const { children, classes, onClose, ...other } = props;
      return (
        <MuiDialogTitle  className={classes.root} {...other}>
          <Typography >{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <Close />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}


export default function Mag_man_mag(props) {
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const classes=useStyles();
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [det, setDet] =useState([]);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const [value, setValue] =useState('1');
    const service="_mag.php"
    
    //remove class to datatable to hide facus on it
    $(function() {
      $("div").removeAttr("data-tag")
    }) 

    //fix number phone to 10
    $(document).ready(function(){
       $('#tel-edit').on('input', function(ev) {
       var $this = $(this);
       var value = $this.val();
       if (value && value.length >10) {
            $this.val(value.substr(0,10));
         }
        })
            });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
    const handleClickOpen = () => { 
        fetchDet()
        setOpen(true); 
      };

    const handleClose = () => { setOpen(false); };

    //fetch details 
    const fetchDet= useCallback(async() => {
        const response = await Gfunc.axiosPostAction(service,"getdet",{magId:parseInt(props.row.DT_RowId.substr(1))})
        if(response.data.err&&response.data.err!=="-1"){
          handleAlert(true,'error',response.data.err)
        }else{
          Gfunc.axiosResponse(response,()=>{ 
          setDet(response.data.jData);   
        })
        }
    },[Gfunc,service,props]);
      
    //Delete user from mag
    const handleDeleteMag=useCallback(async(row)=>{
      const response =await Gfunc.axiosPostAction('_usr.php',"delumag",{idr:parseInt(props.row.DT_RowId.substr(1)), idu:row.userId});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              handleAlert(true,"success","L'utilisateur a été supprimé avec succès .")
              fetchDet();
            }
            
          })
    },[props,Gfunc,fetchDet])

    //Delete subscription from mag
    const handleDeleteAbo=useCallback(async(row)=>{
      const response =await Gfunc.axiosPostAction(service,"delabo",{aboId:row.id,magId:parseInt(props.row.DT_RowId.substr(1))});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
        Gfunc.axiosResponse(response,()=>{
            if(response.data.jData){
              handleAlert(true,"success","L'abonnement a été supprimé avec succès .")
              fetchDet();
            }
            
          })
    },[props,Gfunc,fetchDet])

    const columns1 =useMemo(
      () => [ 
        {
          name: "Utilisateur",
          selector: row=>row.userName,
          width:"180px",
          sortable: true, 
        },
        {
          name: "Actionnaire",
          selector: row=>row.actionnaire,
          minWidth:'150px !important',
          cell: (row) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={row.actionnaire}
                    name="checked"
                    color="primary"
                />
                      }
              />
            );
          },
        },
        {
          name: "Date",
          selector: row=>row.date,
          width:"150px",
          sortable: true, 
        },
        {
          name: "Agent",
          selector: row=>row.agent,
          width:"150px",
          sortable: true, 
        },
        {
          right:true,
          cell: (row) => {
            return (
              <div>
              <div onClick={()=>handleDeleteMag(row)} title="Supprimer utilisateur"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
              </div>
            );
          },
        }
      ],[classes.redIcon,handleDeleteMag]);

      const columns2 =useMemo(
        () => [ 
          {
            name: "Paramètres",
            selector: row=>row.nom,
            width:"700px !important",
            sortable: true, 
            cell: (row) => {
              return (
                <div id='param-val'>
                  {row.nom}
                </div>
              );
            },
          },
          {
            name: "Valeur",
            selector: row=>row.valeur,
            minWidth:'250px !important',
            cell: (row) => {
              return (
                <div>
                  {
                      (row.typ===2)?
                      <div className="checkbox">
                              <FormControlLabel control={<Checkbox checked={(row.valeur==='1')?true:false} className={classes.checkBox}
                                                inputProps={{ 'aria-label': 'controlled' }}/>}
                              /> 
                      </div>
                      :(row.typ===1||row.typ===3)?
                      <div className="smallElem"> {row.valeur}</div>
                      :<div className="smallElem"> 
                          {(Gfunc.getIndex(row.valeurs,parseInt(row.valeur),"id")!==-1)?row.valeurs[Gfunc.getIndex(row.valeurs,parseInt(row.valeur),"id")].nom:''}
                        </div>
                  }
                    </div>
              );
            },
          },
        ],[classes,Gfunc]);

    const columns3 =useMemo(
      () => [ 
        {
          name: "Date début",
          selector: row=>row.debut,
          minWidth:"170px",
          sortable: true, 
        },
        {
          name: "Date fin",
          selector: row=>row.fin,
          minWidth:"150px",
          sortable: true, 
        },
        {
          name: "Montant",
          selector: row=>row.mtn,
          minWidth:'120px !important',
          sortable: true, 
        },
        {
          name: "Ajouter d'ajout",
          selector: row=>row.date,
          minWidth:'160px !important',
          sortable: true, 
        },
        {
          name: "Agent",
          selector: row=>row.agent,
          minWidth:'120px !important',
          sortable: true, 
        },
        {
          right:true,
          cell: (row) => {
            return (
              <div>
              <div onClick={()=>handleDeleteAbo(row)} title="Supprimer abonnement"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
              </div>
            );
          },
      }
      ],[classes.redIcon,handleDeleteAbo]);

      const handleChange = (event, newValue) => {
          setValue(newValue);
      };

      const handleOnEdit=async(e,att,row)=>{
          const value=e.target.innerHTML
          const edt1=Gfunc.DeleteSpace(value)
          if(row[att]!==edt1){
          const response= await Gfunc.axiosPostAction(service,'upd',{att:att,id:props.row.DT_RowId,val:edt1})
          if(response.data.err&&response.data.err!=="-1"){
            handleAlert('true','error',response.data.err)
          }else{
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                localStorage.setItem(att,edt1)
              }
          })
        }
        }
      }

  return (
    <div>
      <div onClick={handleClickOpen}  title="Les informations du magasins"><Info className={classes.redIcon} style={{height:'18px',width:'18px'}}/></div>
        <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsPram}
            id='mag-infos'
         >
        <DialogTitle id="draggable-dialog-title" onClose={handleClose} />
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
            <DialogContent>
            <fieldset className="fieldsetTop_stat" id="stat-mag">
            <div id='infos-log'><DialogLogo handleAlert={handleAlert} row={props.row} logo={det.logo} link={param.logo}/></div> 
             <div id='info-divs'>
             <div id='info-g'>
                <fieldset className="fieldInf" >
                      <legend> Nom:</legend>{(det.name)?det.name:'---'}
                </fieldset>
                <fieldset className="fieldInf" >
                      <legend> Adresse:</legend>{(det.adresse)?det.adresse:'---'}
                </fieldset>
                <fieldset className="fieldInf" >
                  {}
                      <legend> Tel:</legend>{(det.tel)?det.tel.replace(/[Tel: Fax:]/g,''):'---'}
                </fieldset>
                <fieldset className="fieldInf" >
                      <legend> Type:</legend>{(det.type)?det.type:'---'}
                </fieldset>
                <fieldset className="fieldInf" >
                      <legend> Date d'expiration de l'abonnement::</legend>{(det.expireDate)?det.expireDate:'---'}
                </fieldset>
             </div> 
             <div id='info-d'>
                 <fieldset className="fieldInf" >
                      <legend> Étiquette:</legend>
                      <ContentEditable
                          html={(det.etiquette)?det.etiquette:'---'}
                          name="value"
                          data-column="item"
                          className='content-editable-param'
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,'etiquette',det.etiquette)}
                      />
                  </fieldset> 
                  <fieldset className="fieldInf" >
                      <legend> Entête ticket 1:</legend>
                      <ContentEditable
                          html={(det.entet_tiket_1)?det.entet_tiket_1:'---'}
                          name="value"
                          data-column="item"
                          className='content-editable-param'
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,'entetTiket1',det.entet_tiket_1)}
                      />
                  </fieldset>
                  <fieldset className="fieldInf" >
                      <legend> Entête ticket 2:</legend>
                      <ContentEditable
                          html={(det.entet_tiket_2)?det.entet_tiket_2:'---'}
                          name="value"
                          data-column="item"
                          className='content-editable-param'
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,'entetTiket2',det.entet_tiket_2)}
                      />  
                  </fieldset>
                  <fieldset className="fieldInf" >
                      <legend> Facebook:</legend>
                      <ContentEditable
                          html={(det.facebook)?det.facebook:'---'}
                          name="value"
                          data-column="item"
                          className='content-editable-param'
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,'facebook',det.facebook)}
                      /> 
                  </fieldset>
                  <fieldset className="fieldInf" >
                      <legend> Email:</legend>
                      <ContentEditable
                          html={(det.mail)?det.mail:'---'}
                          name="value"
                          data-column="item"
                          className='content-editable-param'
                          disabled={false} 
                          onBlur={e => handleOnEdit(e,'mail',det.mail)}
                      /> 
                  </fieldset>
             </div>
             </div> 
            </fieldset>
                <Box sx={{ width: '95%',margin:'auto' }}>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange} >
                        <Tab label='Utilisateurs' value="1" />
                        <Tab label='Paramètres' value="2" />
                        <Tab label='Abonnements' value="3" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" >
                      <div id='mag-info-usr'>
                        <div id='diag-usr'><DialogUsr det={det} row={props.row} callback={()=>{ fetchDet()}} handleAlert={handleAlert}/></div>
                        <DataTable
                          columns={columns1}
                          data={det.users}
                          noDataComponent={param.ErreurData}
                          defaultSortAsc={true}
                          highlightOnHover
                          customStyles={customStyles}
                          progressComponent={<CircularProgress className="circularProgress" />}
                        />
                      </div>
                    </TabPanel>
                    <TabPanel value="2">
                    <div id='mag-info-usr'>
                       <DataTable
                          columns={columns2}
                          data={det.params}
                          noDataComponent={param.ErreurData}
                          defaultSortAsc={true}
                          highlightOnHover
                          customStyles={customStyles}
                          progressComponent={<CircularProgress className="circularProgress" />}
                        />
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                  <div id='mag-info-usr'>
                      <div id='diag-usr'><DialogAb det={det} row={props.row} callback={()=>{fetchDet()}} handleAlert={handleAlert}/></div>
                       <DataTable
                          columns={columns3}
                          data={det.abo}
                          noDataComponent={param.ErreurData}
                          defaultSortAsc={true}
                          highlightOnHover
                          customStyles={customStyles}
                          progressComponent={<CircularProgress className="circularProgress" />}
                        />
                  </div>
                  </TabPanel>
                </TabContext>
                </Box>
            </DialogContent>
      </Dialog>
      </div>
  );
}
