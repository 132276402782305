import React,{useState,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField,FormControlLabel,Checkbox} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import {axiosPostAction,axiosResponse} from'../../../Gfunc'
import { useStyles, paperPropsSmallF } from '../../../css/muiStyle';
import { Autocomplete } from '@material-ui/lab';
import {AlertCompo} from '../../../js/components'
import $ from 'jquery'

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Usr_man_add(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const [name,setName]=useState('');
    const [pseudo,setPseudo]=useState('');
    const [tel,setTel]=useState('');
    const [selectMag,setSelectMag]=useState('');
    const [open, setOpen] = useState(false);
    const [act, setAct] = useState(false);
    const [mags, setMags] = useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_usr.php"

    //detect changes for phone number
    $(document).ready(function(){
      $('#tel-edit').on('input', function(ev) {
              var $this = $(this);
              var value = $this.val();
              if (value && value.length >10) {
                $this.val(value.substr(0,10));
       }
       })
    });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
   const handleClickOpen = () => { 
      //handleOpenStock()
      setName('');setPseudo('');setSelectMag('');setTel('');setAct(false)
      setOpen(true); 
    };
   const handleClose = () => { setOpen(false); };

    //get mag list
   const fetchMag= useCallback(async() => {
      const response = await Gfunc.axiosGetAction('_mag.php',"gets")
      if(response.data.err&&response.data.err!=="-1"){
         handleAlert(true,'error',response.data.err)
      }else
      Gfunc.axiosResponse(response,()=>{
          setMags(response.data.jData);   
      })
    },[Gfunc]);
    
  //fetch mag handler
  const handleOpenMag = useCallback(async () => {
     if(mags.length===0) {fetchMag()}
  },[mags,fetchMag])

  const handleSubmit= async(e) =>{
        var mag=mags[Gfunc.getIndex(mags,selectMag,"name")].id
        const response=await axiosPostAction(service,"add",{name:pseudo,note:name,tel:tel,magId:mag,actionnaire:act})
        if(response.data.err&&response.data.err!=="-1"){
            handleAlert(true,'error',response.data.err)
        }else{
        axiosResponse(response,()=>{
            if(response.data.jData){
                props.handleAlert(true,'success',"L'utilisateur a été ajouté avec succès")
                props.callback();
                setOpen(false);
            }
        })
      }
  }

  const testPhone=(e)=>{
      const re = /^[0-9\b]+$/;
      if (e.target.value==='' || re.test(e.target.value)) {
        setTel(e.target.value)
      }
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<AddIcon/>}>Nouvel utilisateur </Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouvel utilisateur </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm  onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent id='cat-dialog'>
             <div id='usr-add'>
              <TextField className='smallText' variant='outlined' size="small" label='Pseudo'  value={pseudo} margin='dense'
                        onChange={e =>setPseudo(e.target.value)} fullWidth  required autoFocus />
              <TextField className='smallText' variant='outlined' size="small" label='Nom complet'  value={name} margin='dense'
                        onChange={e =>setName(e.target.value)} fullWidth required />
             </div>
             <div id='usr-add'>
                  <Autocomplete
                      disableClearable
                      className='smallAuto' 
                      id="iMag"
                      onChange={(e,newValue) =>{setSelectMag(newValue);}} 
                      onFocus={handleOpenMag}
                      value={selectMag}
                      options={mags.map((option) => option.name)}
                      renderInput={(params) => (
                        <TextField {...params} label="Magasin" size="small"  margin='dense' variant='outlined' required/>
                                )} /> 
                  <TextField  type="tel" label='Tel' id="tel-edit" name="iTel" value={tel}  className='smallText' margin='dense' variant='outlined'
                            onChange={(e)=>{testPhone(e)}}  inputProps={{ pattern: "[0][1-7][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]"}} required/> 
                  
              </div>
              <div id='usr-add'>
                <FormControlLabel  label="Actionnaire"
                                   control={<Checkbox checked={act} name="checked" color="primary" onChange={e=>setAct(e.target.checked)} />}/>
              </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
                <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
            </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
