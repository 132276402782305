import React,{useState,useRef} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,TextField} from '@material-ui/core';
import Draggable from 'react-draggable';
import SaveIcon from '@material-ui/icons/Save';
import { ValidatorForm} from 'react-material-ui-form-validator';
import {Add} from '@material-ui/icons';
import { useStyles, paperPropsSmallF } from '../../../css/muiStyle';
import {AlertCompo} from '../../../js/components'
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider,KeyboardDatePicker} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import $ from 'jquery'

//add new user
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Mag_man_info_abo(props) {
    const Gfunc=require('../../../Gfunc')
    const classes=useStyles();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedDate1, setSelectedDate1] = useState(Gfunc.YearAfter(new Date()));
    const [open, setOpen] = useState(false);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [mtn, setMtn] =useState('');
    const [severity, setSeverity] =useState("info");
    const service="_mag.php"

    $(document).ready(function(){
       $('#tel-edit').on('input', function(ev) {
       var $this = $(this);
       var value = $this.val();
       if (value && value.length >10) {
            $this.val(value.substr(0,10));
         }
        })
            });

    //handle alerts properties
    const handleAlert=(open,severity,message)=>{
      setOpenA(open)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }
     
    const handleClickOpen = () => { 
          setOpen(true); setSelectedDate(new Date());setSelectedDate1(Gfunc.YearAfter(new Date()));setMtn('')
    };
    const handleClose = () => { setOpen(false); };


    const handleSubmit= async(e) =>{
            const response=await Gfunc.axiosPostAction(service,'addabo',{start:Gfunc.formatDate(selectedDate),end:Gfunc.testDate(selectedDate1),
                                                                        magId:parseInt(props.row.DT_RowId.substr(1)), mtn:parseFloat(mtn)})
            if(response.data.err&&response.data.err!=="-1"){
                handleAlert(true,'error',response.data.err)
            }else
              Gfunc.axiosResponse(response,()=>{
                if(response.data.jData){
                  handleAlert(true,'success',"L'abonnement a été ajouté au"+props.det.name+" avec succès")
                  props.callback();
                  setOpen(false)
                }
            })
    }

    //handle date change 
    const handleDateChange = (date) => {
        setSelectedDate(date);
    }
    //handle date change 
    const handleDateChange1 = (date) => {
        setSelectedDate1(date);
    }

  return (
    <div>
      <Button className={classes.topTabBtn} variant="contained" color="secondary" onClick={handleClickOpen} startIcon={<Add/>}>Nouvel abonnement</Button>
      <Dialog
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            PaperProps={paperPropsSmallF}
      >
        <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Ajouter un nouvel abonnement { (props.det.name)?'à '+props.det.name:''} </DialogTitle>
        <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true} />
        <ValidatorForm ref={useRef(null)} onSubmit={handleSubmit} onError={errors => console.log(errors)} >
            <DialogContent>
            <div id='mag-add-abo'>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    margin="dense"
                                    size="small"
                                    className='smallText'
                                    minDate={new Date()} 
                                    invalidDateMessage="Le format de la date est incorrect"   
                                    minDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                                    variant="inline"
                                    maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    name="iDate"
                                    id="date-picker-inline"
                                    label="Date début"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    required/>
                </MuiPickersUtilsProvider> 
                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    margin="dense"
                                    size="small"
                                    className='smallText'
                                    minDate={new Date()} 
                                    invalidDateMessage="Le format de la date est incorrect"   
                                    minDateMessage="La date ne doit pas être inferieur à la date minimale"                           
                                    variant="inline"
                                    maxDateMessage="La date ne doit pas être supérieure à la date maximale" 
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    name="iDate"
                                    id="date-picker-inline"
                                    label="Date fin"
                                    value={selectedDate1}
                                    onChange={handleDateChange1}
                                    required/>
                </MuiPickersUtilsProvider> 
              </div>
              <div>
                <TextField type='number' className='smallText' margin='dense' variant='outlined' size="small" label="Montant"
                         value={mtn} onChange={e=>setMtn(e.target.value)} fullWidth required/> 
              </div>
            </DialogContent>
            <DialogActions>
           <Button variant="contained" type='submit' color="primary" startIcon={<SaveIcon />}>VALIDER</Button>
           <Button variant="contained" onClick={handleClose} color="secondary">FERMER</Button>
         </DialogActions>
        </ValidatorForm>
      </Dialog>
      </div>
  );
}
