import React ,{ useState} from 'react';
import axios from 'axios';
import '../css/login.css'

const Login=(props)=> {
    const param=props.param;
    const version=require('../version.json');
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    document.title=param.webApp+" "+version[0].build 

    const handlerSubmit= async(e) =>{
                e.preventDefault();
                //Recuperation des donnes du formulaire 
                const email=e.target.email.value;
                const password=e.target.password.value;
                //appelle du fichier jason qui contient url  de service    
                var Gfunc = require('../Gfunc');
                 //envoie des donnees vers le service 
                await axios({
                    method: 'POST',
                    url:param.urlService+'auth.php',
                    headers: { 'content-type': 'application/json' ,'appID': param.appID},
                    data:JSON.stringify({ login: email,password: password })     
                })
            //recuperation des donnees du service pour l'autorisation d'acces ou non 
                .then(response => { 
                  if(response.data.err){
                   alert(response.data.err)
                  }else{
                    Gfunc.axiosResponse(response,()=>{ 
                    localStorage.setItem('token', response.data.jData.token);
                    localStorage.setItem('isLoged', true);
                    localStorage.setItem('name',response.data.jData.name);
                    localStorage.setItem('uid',response.data.jData.id);
                    window.location=('/');
                  });
                 }
                })
                .catch(error => {
                    alert(error);
                    setEmail('');
                    setPassword('');
                })
      }
      
    return (
              <div className="login-page">
              <div className="SingUpLoginBox">
                  <div className="formContainer">
                        <div id="logoDiv"></div>
                        <div>
                        <div id="loginDiv">
                          <form id="f1" onSubmit={handlerSubmit}>
                            <div id="emailDiv"><input type="text" id="email" name="email" placeholder="Votre login" value={email} onChange={e =>setEmail(e.target.value)} /></div>
                            <div id="passDiv"><input type ="password" id="password" name="password" placeholder="Mot de passe" value={password}  onChange={e =>setPassword(e.target.value)} required/></div>
                            <div  id="buttonDiv" ><button id='subo' type="submit"/></div>
                          </form>
                        </div>
                        </div>
                      { /*<div id='pwd-for'> <Pwd/></div>*/}
                        {/* <div id="formDiv">
                            <p id="login">
                                <label>Login</label>
                                <input type="text" id="email" name="email" placeholder="Votre login" value={email} onChange={e =>setEmail(e.target.value)} />
                            </p>
                            <p id="pass">
                              <label>Password</label>
                              <input type ="password" id="password" name="password" placeholder="Mot de passe" value={password} onChange={e =>setPassword(e.target.value)} required/>
                            </p>
                          </div>
                          <div  id="buttonDiv" ><button id='subo' type="submit"/></div> */}
                      
                  </div>
                  <div id="logosDiv">
                    <div id="digitartsDiv"></div>
                    <div id="partnerDiv"></div>
                    </div>
                  <div id="logosDiv2">
                    <div id="bqDiv"></div>
                    <div id="liberoDiv"></div>
                    <div id="webTelcoDiv"></div>
                    <div id="irahtiDiv"></div>
                    <div id="smartTelcoDiv"></div>
                    <div id="smsOrDiv"></div>
                  </div>
              </div>
              <div id="authBottom">
                    <span id="copyr">{param.webApp} {version[0].build} &copy; {param.copyright}</span>
                  </div>
              </div>
              
    )
  }
  
  export default Login;