import { createTheme } from "@material-ui/core";

export const theme = createTheme({
    palette: {
      primary: {light: "#e53935", main: "#990000", dark:"#791915"},//rouge
      secondary: {light: "#f6f6f6",main: "#ccc", dark:"#434343"},//gris      error: {main: "#e53935"},//rouge foncé
      success: {main: "#388e3c"},//vert
      bleu: {light:'#c5cae9',main:'#3f51b5',dark:"#0e073a"}
    },
    typography: {
      button: {
        fontSize: 13,
        fontWeight: "bold",
      },
      h6: {
        fontSize: '15px !important',
        fontWeight: "600",
      },
    },
    overrides: {
      Menu:{
        root:{
          fontSize:'12px !important',
        }
      },
      MuiMenuItem: {
        root: {
         fontSize:'13px !important'
        },
      },
      MuiAutocomplete: {
        paper: {
          width:'fit-content',
          minWidth:'100%',
          whiteSpace:"nowrap",
          fontSize:'13px !important',
        },
        option: {
          background:'white !important',
          '&[data-focus="true"]': {
            backgroundColor: '#eee !important',
          },
        },
      },
    },
  });