import React,{useState,useCallback, useEffect} from 'react';
import {IconButton,Collapse,Tooltip, CircularProgress,TextField} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import {Close} from '@material-ui/icons';
import {useStyles} from '../css/muiStyle';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import NoData from '../img/nodata-found.svg'
import NoAjust from '../img/noajust.png'
import NotAllowed from '../img/sorryicon.png'

const useStyles2 = makeStyles((theme) => ({
    root: {
     /* width: '98%',
      margin:"0px 1%",
      '& > * + *': {
        marginTop: theme.spacing(2),
      },*/
      position:'fixed',
      bottom:"30px",
      right:'10px',
      zIndex: theme.zIndex.drawer + 1,
      width: '300px',
      opacity:1
    },
    root2: {
     /* width: '98%',
      margin:"0px 1%",
      '& > * + *': {
        marginTop: theme.spacing(2),
      },*/
      position:'fixed',
      top:"35px",
      zIndex: theme.zIndex.drawer + 1,
      width: '95%',
      marginLeft:'2.5%',
      marginRight:'2.5%',
      opacity:0.7
    },
  }));

const Gfunc=require('../Gfunc')
const librery=require('./data_table_librery')
//const mdi=require('@mdi/js');

export const AlertCompo=(props)=>{
    const classes=useStyles2();
    return (
      <div className={(props.dialg)?classes.root2:classes.root}>
        <Collapse in={props.open}>
          <Alert
          variant="filled"
          severity={props.severity}
          style={{whiteSpace: "pre-wrap"}}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.setOpen();
                }}
              >
                <Close fontSize="inherit" />
              </IconButton>
            }
          >
            {props.message}
          </Alert>
        </Collapse>
      </div>
    )
  }

  export function AutocompleteS({service,action,row,list,id,name,onOpen,attr,handleAlert,from,rowId,dateupd,agentupd,datein,
                                 agentin,handleResponse,handleResponsee,smen,mag,className,attri}) {
    const classes = useStyles();
    const [selecto,setSelecto]=useState('')
    const [Item,setItem]=useState('')
    const [ItemN,setItemN]=useState('')
    const [,setValue]=useState('')


    const handleSelect=(row,att)=>{
      setSelecto(row[att])
      setItem(row[rowId])
      setItemN(att)
    }

    const handleOnEditSelect=async(val,attList)=>{
      const rowid=(rowId!==undefined)?rowId:"DT_RowId"
      if(row[id]!==val){
       const edt=val
       var response=''
       if(mag==='mag'){
         response=await librery.axiosEdit2(service,action,row,(edt!=='empty')?edt:'',id,rowid) 
       }else{
         response=await librery.axiosEdit2(service,action,row,edt,id,rowid) 
       }
        if(response.data.err&&response.data.err!=='1'){
          handleAlert(true,'error',response.data.err)
        }else
         Gfunc.axiosResponse(response,()=>{
          const objIndex =list.findIndex((obj => obj[attri] === edt));
          if(mag==='mag'){
            row[name]=(edt!=='empty')?val:''
            row[id]=(list[objIndex][attList]!=='Aucun')?list[objIndex][attList]:''
            setValue(row[id])
            setItemN('')
          }else{
            row[name]=list[objIndex][attList]
            setValue(row[name])
            if(mag!=='mag-at'&&mag!=='mag-acc'&&mag!=='mag-ph'){
              row[id]=edt
            }
            if(handleResponse){
              handleResponse(response.data)
            }
            if(handleResponsee){
              handleResponsee(edt)
            }
           /* if(smen!==''){
              document.getElementById(smen).innerHTML=row[name]
            }*/
            setSelecto('')
            setValue('')
          }
      })
    }
    }

    return(
      <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
      <div className={(from==='inf')?"smallSelectDT2":"smallSelectDT"}>
    {
      ((row[rowId]===Item || from==='inf') && row[name]===selecto && ItemN===name)?
        <ClickAwayListener onClickAway={()=>{setSelecto('');setValue('');setItem('')}}>
            <div>
             <Autocomplete 
                disableClearable
                id={id}
                key={(mag==='mag')?row[id]:row[name]}
                onBlur={()=>{setSelecto('');setValue('')}}
                loading={list.length===0}
                loadingText="Chargement..."
                defaultValue={(mag==='mag')?row[id]:row[name]}
                onChange={(e,newValue)=>{e.stopPropagation();handleOnEditSelect(list[Gfunc.getIndex(list,newValue,attr)][attri],attr)}}
                onOpen={onOpen}
                options={list.map((option) => option[attr])}
                inputprops={{className:classes.diagFormInput}}
                renderInput={(params) => (
                <TextField autoFocus {...params} color="primary" variant="outlined" size="small"/>)} />
            </div>
          </ClickAwayListener>
        :(row[name]!==null)?
          <div className={className} id={id} onClick={()=>{handleSelect(row,name)}}>{(mag==='mag')?(row[id]):row[name]} </div>
        :<div className={(mag!=='mag')?"div-vide":"div-vide-1"} id={id} onClick={()=>{handleSelect(row,name)}}></div>
    }
    </div></Tooltip>
    )
  }

  export const NoDataComponent =((props) => {
    return (
    <div className='nodata'>
      <div id='cont'>
      <img src={(props.ajust)?NoAjust:NoData} alt="NoData" className='notFound' />   
      <p className='message'>{props.message}</p>  
     </div>  
    </div>      
    );
  }); 

  export const NotAllowedComponent =((props) => {
    return (
    <div className='nodata'>
      <div style={{width:"100%",padding:'0px auto'}}>
      <img src={NotAllowed} alt="NoData" className='notAllowed' /> 
      </div>  
      <p className='message'>{props.message}</p>  
    </div>        
    );
  });
  
  // editable input of type number 
export function TelUpd({service,handleAlert,row,att,action,id,value,idi,min,max,traetResp,dateupd,agentupd,datein,agentin,positif,loading,setLoading,charge,supp,suppAtt}) {
  const [open, setOpen] = useState('');
  const [val, setVal] = useState(value);
  const [changed, setChanged] = useState(false);
  const [load, setLoad] =useState(false);
  
 //update field for formatted numbers
 //update field for formatted numbers
 const handleOnEdit2=useCallback(async(val)=>{
  if(val!==''){
    const check=Gfunc.phonenumber(val)
  if(check.response &&val!==null){
  setLoad(true)
  const edt1=val
  var edt=edt1.replace(/\s/g, '');
      if(row[att]!==edt&&edt!==""){
        const attrib=row[att]; 
        const response=await librery.axiosEdit2(service,action,row,edt,att,id,"number",supp,suppAtt) 
        if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
        }else
        Gfunc.axiosResponse(response,()=>{
          setVal(edt);
          row[att]=edt
         // e.target.value=new Intl.NumberFormat().format(edt)
          if(traetResp!==undefined)
            traetResp(response.data)
        })
        if(response.data.err) {setVal(attrib)}
      }
      setLoad(false)
    }
    else {
        handleAlert(true,'error',check.message);setVal(value)}
    setOpen('')
    }
},[row,att,action,id,traetResp,handleAlert,service,supp,suppAtt,value]);

useEffect(() => {
  if(open===row[id]){
    const elem=document.getElementById(idi)
    if (elem) {elem.focus(); elem.select()}
  }
}, [open,idi,id,row]);
  
return (
  <Tooltip title={(datein!==undefined||dateupd!==undefined)?((!dateupd)?"Entré le: "+datein +' par: ' +agentin:"Édité le: "+dateupd +' par: ' +agentupd):''}>
  <div className='InputNbrEditable'>
  {(open!==row[id])?<div id={idi} className="content-editable" style={{textAlign:'center',justifyContent:"center"}} onClick={()=>setOpen(row[id])}>{(val!==null)?val:''}</div>
  :<ClickAwayListener onClickAway={e => {if(changed){handleOnEdit2(val);}else setOpen('')}}>
    {(!load)?
    <input 
    id={idi}
    key={row[id]}
    //onBlur={()=>{setSelecto('');setValue('')}}
    defaultValue={value}
    value={(val===null)?'':val}
    type="number"  
    style={{color:"black !important"}}
    name="iTel" className='content-editable removeArrow' margin='dense' variant='outlined'
    autoFocus
    onChange={(e)=>{
      if(value!==e.target.value) setChanged(true); else setChanged(false)
      if(e.target.value && e.target.value.length >=10){setVal(e.target.value.substr(0,10))}else{setVal(e.target.value)}
    }}
    onKeyPress={(e)=>{
      if(e.key==="Enter"){
      handleOnEdit2(val);}
      }}
    required
    /> 
:<CircularProgress style={{width:"12px",height:"12px"}} color="inherit" />}
  </ClickAwayListener>
  }
  </div>
  </Tooltip>

);
}