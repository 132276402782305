import React,{useState,useMemo,useCallback} from 'react';
import {DialogActions,DialogContent,DialogTitle,Paper,Button,Dialog,FormControlLabel,Checkbox} from '@material-ui/core';
import DataTable from "react-data-table-component";
import Draggable from 'react-draggable';
import {IndeterminateCheckBox,Add} from '@material-ui/icons';
import {axiosResponse,axiosPostAction,removeItemsByAtt} from'../../../Gfunc'
import Multiselect from 'multiselect-react-dropdown';
import { useStyles,paperPropss, multiSelect } from '../../../css/muiStyle';
import {customStyles}from '../../../css/datatable-custom'
import {AlertCompo} from "../../../js/components"
import ParamContext from '../../../Contexte';
import { useContext } from 'react';
import {AddBusiness} from '@mui/icons-material';

//the dialog that adds users to a role**********
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export default function Usr_man_mags(props) {
    const classes=useStyles();
    const Gfunc=require('../../../Gfunc')
    const param=useContext(ParamContext);
    const [open, setOpen] = useState(false);
    const [option, setOption] = useState([]);
    const [data, setData] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [openA, setOpenA] =useState(false);
    const [messageA, setMessageA] =useState('');
    const [severity, setSeverity] =useState('info');
    var users =[]
    const service="_usr.php"

    const handleAlert=(open,severity,message)=>{
      setOpenA(true)
      setSeverity(severity)
      setMessageA(message)
      setTimeout(function(){
        setOpenA(false)
      }, 5000);
    }

    const handleClickOpen = () => { 
      fetchMags();
      fetch(); 
      setOpen(true)
    };

    const handleClose = () => { setOpen(false); };

    //fetch the mag that have the selected user
    const fetch= useCallback(async() => {
      const data={idu:props.row.DT_RowId.substr(1),unsets:false}
      const response=await Gfunc.axiosPostAction(service,"getumags",data)
      if(response.data.err&&response.data.err!=='-1'){
        props.handleAlert(true,"error",response.data.err)
        setOpen(false)
      }else{
        Gfunc.axiosResponse(response,()=>{
          if(response.data.jData){
             setData(response.data.jData);
          }
        })
      }
    },[props])

    //fetch all magasins that doesn't have the selected user
    const fetchMags=useCallback(async() => {
      const data={idu:props.row.DT_RowId.substr(1),unsets:true}
      const response=await Gfunc.axiosPostAction(service,"getumags",data)
            if(response.data.err&&response.data.err!=='-1'){
              props.handleAlert(true,"error",response.data.err)
              //setOpen(false)
            }else
              if(response.data.jData!==undefined){
                axiosResponse(response,()=>{
                setOption(response.data.jData);
                })
              }
    },[props])

    //Multiselect functions********************
    function onSelect(selectedList, selectedItem) {
      users=selectedList;
      setSelectedValues(users)
    }

     //suppression des options
    function onRemove(selectedList, removedItem) {
       setSelectedValues(selectedList)
    }
  
    //Add mags to user
    const handleAddMag=useCallback(async() => {
      if(selectedValues.length>0){
          var idrss=[]
          selectedValues.map((opt)=>{
            idrss.push(opt.id) 
            return true 
          })
        const response= await Gfunc.axiosPostAction(service ,"setumags",{idu:props.row.DT_RowId.substr(1),idrs:idrss})
          if(response.data.err&&response.data.err!=='-1'){
            setOpen(false)
            props.handleAlert(true,"error",response.data.err) 
          }else{
            Gfunc.axiosResponse(response,()=>{
              if(response.data.jData){
                handleAlert(true,"success","L'utilisateur a été attribués avec succès aux magasins sélectionnés.")
                selectedValues.map((item)=>{
                  removeItemsByAtt(option,item.id,"id")
                  data.push(item)
                  return true;
                })
                //props.callback();
              }
            })
          } 
            setSelectedValues([])
      }else {handleAlert(true,"warning","Vous devez sélectionner au moins un élement!")}
    },[data,option,selectedValues,service,props.row.DT_RowId])
  
    //Delete magasin from users
    const handleDeleteMag=useCallback(async(row)=>{
      const response =await Gfunc.axiosPostAction(service,"delumag",{idu:props.row.DT_RowId.substr(1), idr:row.id});
      if(response.data.err&&response.data.err!=='-1'){
        handleAlert(true,'error',response.data.err)
      }else
          axiosResponse(response,()=>{
            if(response.data.jData){
              handleAlert(true,"success","Le magasin a été supprimé avec succès .")
              fetch();
              //props.callback();
            }
            
          })
    },[props])
  
    const handleChange=async(val,row)=>{
      const response =await Gfunc.axiosPostAction(service,"setacc",{userId:parseInt(props.row.DT_RowId.substr(1)),magId:row.id,itIs:val});
      if(response.data.err&&response.data.err!=='-1'){
          handleAlert(true,'error',response.data.err)
      }else
          Gfunc.axiosResponse(response,()=>{
            if(response.data.jData===true){
              row.actionnaire=val
            }else{
              row.actionnaire=!val
            }  
          })
      }

    const columns =useMemo(
      () => [   
        {
          name: "Magasin",
          selector:row=> row.name,
          sortable: true,
          minWidth:'350px !important'
        },
        {
          name: "Actionnaire",
          minWidth:'150px !important',
          cell: (row) => {
            return (
              <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={row.actionnaire}
                  onChange={e=>handleChange(e.target.checked,row)}
                  name="checked"
                  color="primary"
              />
                    }
            />
            );
          },
        },
        {
          right:true,
          cell: (row) => {
              return (
                <div onClick={()=>handleDeleteMag(row)} title="Supprimer magasin"><IndeterminateCheckBox className={classes.redIcon} fontSize="small"/></div>   
              );
          },
        }  
      ],[handleDeleteMag,classes]);
  

  return (
       <div>
            <div title="Affecter des magasins">
               <AddBusiness className={classes.blueIcon} onClick={handleClickOpen} style={{heigth:'23px',width:'23px'}}/>
            </div>
            <Dialog
                    open={open}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    PaperProps={paperPropss}
            >
                <DialogTitle className={classes.topDivTitle} id="draggable-dialog-title" >Magasins du <b>{props.row.user}</b></DialogTitle>
                <AlertCompo severity={severity} open={openA} setOpen={setOpenA} message={messageA} dialg={true}/>
                <DialogContent>
                <fieldset className="fieldsetTop" id="usr">
                  <legend>Ajouter des magasins</legend>
                {
                  (option.length!==0)?
                    <div id='row-1'>
                          <Multiselect
                            style={multiSelect}
                            options={option} 
                            selectedValues={selectedValues}
                            onSelect={onSelect} 
                            onRemove={onRemove} 
                            emptyRecordMsg="Il n'y a plus d'options"
                            displayValue="name" 
                            placeholder="Selectioner les magasins"
                            closeOnSelect={false}
                            hidePlaceholder={true}
                          />
                    <div><Button className={classes.TabBtn} variant="contained" onClick={handleAddMag} color="primary" startIcon={<Add/>}>AJOUTER</Button></div>
                    </div>:<></>
                }
              </fieldset>
            <div id='stk-usr'>
              <DataTable 
                  className="table"
                  persistTableHead
                  columns={columns}
                  data={data}
                  noHeader={true}
                  customStyles={customStyles}
                  defaultSortAsc={true}
                  noDataComponent={param.ErreurData}    
                  highlightOnHover
                  paginationComponentOptions={{ rowsPerPageText: 'Element par page:', rangeSeparatorText: 'sur'}}               
              />
           </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" autoFocus onClick={handleClose} color="secondary">FERMER</Button>
        </DialogActions>
      </Dialog>
      </div>
  );
}
